export function Finish({ children }) {
  return (
    <div className="wrapper">
      <header>Merci pour votre participation ! 🙏</header>
      <p className="mt-20">
        Merci d'avoir participé à l'expérience collective !
      </p>
      <div className="buttons-group">{children}</div>
    </div>
  );
}
