import { AnimatePresence, motion } from "framer-motion";
import usePartySocket from "partysocket/react";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  GlobalState,
  type Question,
  type StateQuestion,
} from "../../shared/types";
import { CountDown } from "./CountDown";
import { Finish } from "./Finish";
import { Poll } from "./Poll";
import { Status } from "./Status";
import { Welcome } from "./Welcome";

export default function Game() {
  const [status, setStatus] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [question, setQuestion] = useState<Question | null>(null);
  const [nbUsers, setNbUsers] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [stateQuestion, setStateQuestion] = useState<StateQuestion | null>(
    null
  );
  const [globalState, setGlobalState] = useState(GlobalState.Started);
  const [ownResponse, setOwnResponse] = useState<number | null>(null);
  const [pollResult, setPollResult] = useState<number[] | null>(null);
  const [answer, setAnswer] = useState<number | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("party-token");
    setIsAdmin(token != null);
  }, []);

  const socket = usePartySocket({
    room: "game-room",
    query: async () => ({
      token: localStorage.getItem("party-token"),
    }),
    onOpen: () => {
      setStatus(true);
    },
    onMessage: (evt) => {
      const object = JSON.parse(evt.data);
      if (object.type == "pong") {
        // @ts-ignore
        const latency = new Date().getTime() - window["ping"];
        toast(`Latence: ${latency}ms`, { icon : "🏓" });
      }
      if (object.question) {
        setQuestion(object.question);
        setOwnResponse(null);
        setPollResult(null);
        setAnswer(null);
      }
      if (object.timeLeft != undefined) {
        setTimeLeft(object.timeLeft);
      }
      if (object.nbUsers != undefined) {
        setNbUsers(object.nbUsers);
      }
      if (object.stateQuestion != undefined) {
        setStateQuestion(object.stateQuestion);
      }
      if (object.globalState != undefined) {
        setGlobalState(object.globalState);
        if (object.globalState == GlobalState.Started) {
          setQuestion(null);
        }
      }
      if (object.ownResponse != undefined) {
        setOwnResponse(object.ownResponse);
      }
      if (object.pollResult != undefined) {
        setPollResult(object.pollResult);
      }
      if (object.answer != undefined) {
        setAnswer(object.answer);
      }
    },
    onClose: () => {
      setStatus(false);
    },
    onError: () => {
      setStatus(false);
    },
  });

  const sendResponse = (index: number) => {
    toast.success("Votre réponse a été envoyée !");
    socket.send(JSON.stringify({ type: "response", index }));
  };

  const nextQuestion = () => {
    socket.send(JSON.stringify({ type: "next" }));
  };

  const restart = () => {
    socket.send(JSON.stringify({ type: "restart" }));
  };

  return (
    <div>
      <main>
        <AnimatePresence mode="wait">
          <motion.div
            key={question ? question.question : "state" + globalState}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {question && globalState != GlobalState.Ended && (
              <>
                <CountDown timeLeft={timeLeft} />

                <Poll
                  question={question}
                  onSubmit={sendResponse}
                  ownResponse={ownResponse}
                  displayResults={!!pollResult}
                  pollResult={pollResult}
                  answer={answer}
                >
                  {isAdmin && (
                    <button className="button-77" onClick={nextQuestion}>
                      Suivant !
                    </button>
                  )}
                </Poll>
              </>
            )}

            {globalState == GlobalState.Started && (
              <>
                <Welcome>
                  {isAdmin && (
                    <button className="button-77" onClick={nextQuestion}>
                      Commencer !
                    </button>
                  )}
                </Welcome>
              </>
            )}
            {globalState == GlobalState.Ended && (
              <Finish>
                {isAdmin && (
                  <button className="button-77" onClick={restart}>
                    Recommencer !
                  </button>
                )}
              </Finish>
            )}
          </motion.div>
        </AnimatePresence>
      </main>
      <Toaster />
      <Status status={status} nbUsers={nbUsers} socket={socket} />
    </div>
  );
}
