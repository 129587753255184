import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import type { Question } from "../../shared/types";

interface PollProps {
  question: Question;
  onSubmit: (selected: number) => void;
  children: any;
  ownResponse: number | null;
  displayResults: boolean;
  pollResult: number[] | null;
  answer: number | null;
}

export function Poll({
  question,
  onSubmit,
  children,
  ownResponse,
  displayResults,
  pollResult,
  answer,
}: PollProps) {
  const [selected, setSelected] = useState<number | null>(null);
  const [hasVoted, setHasVoted] = useState(false);
  
  useEffect(() => {
    setSelected(ownResponse)
    setHasVoted(ownResponse != undefined)
  }, [question])

  return (
    <div className="wrapper">
      <header>{question.question}</header>
      <div className="poll-area">
        <input type="checkbox" name="poll" id="opt-1" />
        <input type="checkbox" name="poll" id="opt-2" />
        <input type="checkbox" name="poll" id="opt-3" />
        <input type="checkbox" name="poll" id="opt-4" />
        {
          // repeat the above label for each option
          question.options.map((option, index) => (
            <label
              htmlFor={`opt-${index + 1}`}
              className={`opt-${index + 1} ${
                answer != null && answer == index ? " good" : ""
              } ${
                selected == index ? " selected" : ""
              }`}
              onClick={() => setSelected(index)}
            >
              <div className="row" key={index}>
                <div className="column">
                  <span className="circle"></span>
                  <span className="text">{option.text}</span>
                </div>
                {displayResults && (
                  <motion.span
                    className="percent"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    {(pollResult?.[index] ?? 0) + "%"}
                  </motion.span>
                )}
              </div>
              <AnimatePresence>
                {displayResults && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="progress"
                    style={{ "--w": pollResult?.[index] ?? 0 }}
                  ></motion.div>
                )}
              </AnimatePresence>
            </label>
          ))
        }
      </div>
      <div className="buttons-group">
        {!displayResults && (
          <button
            className="button-77"
            disabled={selected == null || hasVoted}
            role="button"
            onClick={() => {
              setHasVoted(true);
              onSubmit(selected);
            }}
          >
            Voter !
          </button>
        )}
        {children}
      </div>
    </div>
  );
}
