export function Welcome ({ children }) {
    return (
        <div className="wrapper">
            <header>Bienvenue à l'expérience collective !</header>
            <p className="mt-20">
                Le but de cette expérience est de répondre à des questions en groupe. Vous avez 30 secondes pour répondre à chaque question. Les réponses sont anonymes et les résultats seront affichés à la fin de chaque question.

                Est-ce que tout le monde est prêt ?
            </p>

            <div className="buttons-group">
                {children}
            </div>
        </div>
    )
}