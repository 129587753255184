// https://codepen.io/geoffgraham/pen/yLywVbW
// Credit: Mateusz Rybczonec

import { TIME_LEFT } from "../../shared/constants";

function formatTime(time: number) {
  const minutes = Math.floor(time / 60);
  let seconds = time % 60;

  if (seconds < 10) {
    seconds = `0${seconds}` as any;
  }

  return `${minutes}:${seconds}`;
}

const FULL_DASH_ARRAY = 283;

function calculateTimeFraction(timeLeft: number) {
  const rawTimeFraction = timeLeft / TIME_LEFT;
  return rawTimeFraction - (1 / TIME_LEFT) * (1 - rawTimeFraction);
}

export function CountDown({ timeLeft }: { timeLeft: number }) {
  const circleDasharray = `${(
    calculateTimeFraction(timeLeft) * FULL_DASH_ARRAY
  ).toFixed(0)} 283`;

  return timeLeft == 0 ? (
    <h1>
      Résultat du vote ✌️
    </h1>
  ) : (
    <div className="base-timer">
      <svg
        className="base-timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="base-timer__circle">
          <circle
            className="base-timer__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <path
            id="base-timer-path-remaining"
            stroke-dasharray={circleDasharray}
            className={
              "base-timer__path-remaining green" +
              (timeLeft <= 15 ? " orange" : "") +
              (timeLeft <= 5 ? " red" : "")
            }
            d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
          ></path>
        </g>
      </svg>
      <span id="base-timer-label" className="base-timer__label">
        {timeLeft}
      </span>
    </div>
  );
}
