import type PartySocket from "partysocket";

interface StatusProps {
  status: boolean;
  nbUsers: number;
  socket: PartySocket;
}

export function Status({ status, nbUsers, socket }: StatusProps) {

  const ping = () => {
    // @ts-ignore
    window['ping'] = new Date()
    socket.send(JSON.stringify({ type: "ping" }));
  }

  return (
    <div className="status">
      {
        status ? (
            <div className="online-msg" onClick={ping}>Vous êtes connecté {
              nbUsers > 1 ? `avec ${nbUsers - 1} autre${nbUsers > 2 ? 's' : ''} joueur${nbUsers > 2 ? 's' : ''}` : ''
            } 🔗</div>
        ) : (
            <div className="offline-msg">Vous êtes hors-ligne 😢</div>
        )
      }
    </div>
  );
}
